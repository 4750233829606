import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { snackbarNotificationsState } from 'state-domains/domain';
import { UserPortalState } from 'src/state';

const mapStateToProps = (state: UserPortalState) => {
    const {
        selectors: { snackbarNotificationArray },
    } = snackbarNotificationsState;
    return {
        notificationList: snackbarNotificationArray(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    const {
        actions: { deleteSnackbarMessage, clearSnackbarMessages },
    } = snackbarNotificationsState;
    return {
        deleteSnackbarMessage: bindActionCreators(deleteSnackbarMessage, dispatch),
        clearSnackbarMessages: bindActionCreators(clearSnackbarMessages, dispatch),
    };
};

export const connectToState = (component: React.ComponentType<any>) =>
    connect(mapStateToProps, mapDispatchToProps)(component);
